import { Link } from 'gatsby'
import React from 'react'
import HeroText from '../../components/hero-text'
import EmailSignup from '../../components/email-signup'
import styles from './home-hero.module.scss'

const HomeHero = () => {

    return (
        <section className={styles.main}>
            <div className="container">
                <div className={styles.logoLockup}>
                    <img src="/logo-bg.svg" alt=""/>
                    <Link to="/" className={styles.logo}>
                        <img src="/invirsa_logo_dark_bg.svg" alt="Invirsa"/>
                    </Link>
                    <img className={styles.eyePic} src="/home-hero-eye.png" alt=""/>
                </div>
                <div className={styles.text}>
                    <HeroText>
                        <h1>Damage control at the <strong>DNA level</strong></h1>
                        <p>Invirsa’s lead compound (INV-102), quickly and predictably augments the body’s response to DNA damage, greatly improving cell stability at the critical moment.</p>
                    </HeroText>
                    <img className={styles.doc} src="/home-doc@2x.png" alt=""/>
                </div>
                <div className={styles.emailContainer}>
                    <div className={styles.emailContainerContent}>
                        <h4>Announcement</h4>
                        <p className={styles.emailContainerNews}>Invirsa Announces 5 year, $29.6 million partnership with BARDA to develop a treatment for ocular conditions associated with DNA damage, including Sulfur Mustard Gas injury.</p>
                        <p className={styles.signupDescription}>Invirsa is on the move. Stay up to date with the latest developments:</p>
                        <EmailSignup />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeHero