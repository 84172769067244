import React from "react"
import HomeHero from "../fragments/home/home-hero"
import HomeIndications from "../fragments/home/home-indications"
import HomeInvestors from "../fragments/home/home-investors"
import HomeNews from "../fragments/home/home-news"
import MainLayout from "../layout/main-layout"

export default function Home() {
  return (
    <MainLayout homeHeader={true}>
      <HomeHero></HomeHero>
      <HomeIndications></HomeIndications>
      <HomeInvestors></HomeInvestors>
      <HomeNews></HomeNews>
    </MainLayout>
  )
}
