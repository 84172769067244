import React from 'react'
import { useEffect, useRef } from 'react';
import styles from './dna.module.scss';

const Dna = ({className}) => {
    const dna = useRef(null);
    
    const onScroll = function() {
        dna.current.querySelectorAll('path').forEach( path => {
            if(path.getBoundingClientRect().top < window.innerHeight / 1.5) {
                path.style.opacity = 1;
            }
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    })

    return (
        <svg className={[styles.dna, className].join(' ')} ref={dna} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.065 912.105">
            <g id="DNA" opacity="0.352">
                <path id="Path_100" data-name="Path 100" d="M384.43,43.968c-9.046-.184-15.2-3.54-19.885-10.15a9.518,9.518,0,0,0-6.035-3.839c-23.426-1.77-46.863-3.92-70.334-4.6-43.4-1.149-86.84-2.3-130.254-1.885-36.862.345-73.7,2.9-110.565,4.264-3.943.149-5.058,2.471-6.6,5.161A21.621,21.621,0,0,1,17.9,43.635C9.218,42.095,1.919,35.037.437,26.75c-1.793-9.977,2-18.782,10.207-23.667C20.368-2.722,32.655.025,39.8,9.221a9.977,9.977,0,0,0,6.253,3.7c32.414,2.115,64.84,4.759,97.3,5.667q59.955,1.667,119.967.8c31.9-.46,63.771-2.713,95.633-4.437,1.816-.1,4.023-2.3,5.138-4C369.372,2.657,379.154-1.688,388.3.611c9.414,2.3,16.437,10.793,16.747,20.138.345,10.69-5.379,18.989-15.437,22.2A39.012,39.012,0,0,1,384.43,43.968Z" transform="translate(0 0)" fill="#1f3667"/>
                <path id="Path_103" data-name="Path 103" d="M49.413,17.757c21.207,1.08,42.759,2.77,64.369,3.161,39.426.724,78.875,1.517,118.277.7,34.782-.713,69.507-3.54,104.243-5.667a9.54,9.54,0,0,0,5.92-3.563c6.9-8.667,17.644-10.414,25.736-3.839a17.575,17.575,0,0,1,1.747,25.1c-7.046,7.793-18.391,7.471-26.437-.494a9.874,9.874,0,0,0-5.747-2.943c-24.242-1.437-48.495-3.058-72.759-3.77-26.782-.782-53.621-1.506-80.38-.736-42.437,1.218-84.84,3.713-127.254,5.621-4.954.23-8.23,1.724-10.9,6.759C40.781,48.343,28.494,52.481,17.493,49.114A25.2,25.2,0,0,1,19.057.688,25.2,25.2,0,0,1,46.908,12.941C47.724,14.274,48.356,15.665,49.413,17.757Z" transform="translate(12.61 65.568)" fill="#1f3667"/>
                <path id="Path_104" data-name="Path 104" d="M28.433.076C39.008.191,47.559,4.674,52.571,13.8c2.644,4.793,5.747,5.747,10.437,5.747,35.184,0,70.426,1.368,105.53-.3C204.745,17.524,240.814,12.9,276.93,9.34c2.011-.2,4.241-1.977,5.678-3.632,5.747-6.529,15.15-7.678,21.575-2.3A15.126,15.126,0,0,1,306.045,24.9c-5.575,6.425-14.69,6.8-21.621,1.414a14.46,14.46,0,0,0-8.127-2.966c-35.943-.287-71.932-1.586-107.806-.069C133.032,24.8,97.7,29.237,62.3,32.352c-3.713.333-5.828,1.563-7.149,5.747-4.46,14.035-19.4,21.759-33.966,18.219C7.2,52.87-1.579,39.548.237,24.41,1.938,10.467,13.95.122,28.433.076Z" transform="translate(50.637 126.514)" fill="#1f3667"/>
                <path id="Path_102" data-name="Path 102" d="M33.074,9.6c12.092.322,21.081,5.437,28.184,14.747a10.023,10.023,0,0,0,6.678,3.782c42.391,1.034,83.691-5.747,124.139-18.219,3.54-1.092,6.828-3.7,9.632-6.253,4.115-3.759,10.483-4.931,15.322-2.046A12.391,12.391,0,0,1,222.1,16.624c-1.908,5.035-7.782,9.035-12.8,7.23-13.793-4.943-27.724-2.207-41.38-1.149A406.223,406.223,0,0,0,71.592,41.739c-3.448,1.149-4.8,2.747-5.379,6.172C63.144,66.13,49.121,77.234,30.58,76.372,14.856,75.636,1.361,62.13.108,45.877c-1.333-17.46,9.736-32.115,27.115-35.759A44.981,44.981,0,0,1,33.074,9.6Z" transform="translate(96.663 174.392)" fill="#1f3667"/>
                <path id="Path_106" data-name="Path 106" d="M35.691,13.811C47.426,13.6,56.472,17.57,63.576,25.535c2.3,2.621,4.448,3.448,7.31,1.644,5.747-3.7,11.494-7.414,16.92-11.494,1.149-.839,1.517-3.184,1.609-4.874C89.818,3.857,94.7-.591,101.5.064c6.115.6,10.081,5.667,9.425,12.081s-6.035,10.23-12.724,9.2c-8.379-1.276-22.77,5.23-26.839,12.575-.793,1.437-.184,3.931.333,5.747A36.6,36.6,0,1,1,2.483,36.846C8.024,22.489,20.943,13.811,35.691,13.811Z" transform="translate(178.358 216.998)" fill="#1f3667"/>
                <path id="Path_113" data-name="Path 113" d="M138.644,69.1c-15.345,0-28.023-9.287-32.253-24.242a9.632,9.632,0,0,0-5.989-6.8A237.934,237.934,0,0,0,25.355,18.813c-3.149-.3-6.632.793-9.667,1.989-5.437,2.138-11.264.736-14.081-3.989A11.23,11.23,0,0,1,4.5,2.031c4.7-3.276,11.747-2.644,14.77,2.3,2.678,4.414,6.655,5.931,10.862,7.563C53.126,20.8,76.942,25.571,101.4,27.318c3.069.218,4.678-.747,5.989-3.759,6.54-15.069,20.69-23.23,35.69-20.989s26.092,13.586,28.541,29.46C174.587,51.02,158.529,69.032,138.644,69.1Z" transform="translate(135.118 296.596)" fill="#1f3667"/>
                <path id="Path_115" data-name="Path 115" d="M192,48.45c5.552-11.747,13.793-18.759,26.782-18.977,15.54-.264,27.4,10.414,29.173,26.437,1.494,13.471-8.874,26.931-23.1,29.977-13.644,2.931-28.518-5.517-32.506-19.127-1.207-4.1-3.253-5.747-6.954-7.15A712.237,712.237,0,0,0,49.111,25.128c-6.989-1.081-13.966-2.368-21-2.92A33.77,33.77,0,0,0,16.49,23.5C10.478,25.2,4.179,23,1.317,17.484A12.046,12.046,0,0,1,5.466,1.921a12.851,12.851,0,0,1,16.184,2.2,21.919,21.919,0,0,0,7.529,5.437c38.115,14.529,77.5,24.483,117.542,31.862C161.721,44.128,176.859,46.105,192,48.45Z" transform="translate(105.063 336.936)" fill="#1f3667"/>
                <path id="Path_111" data-name="Path 111" d="M302.945,105.464c-12.207,0-21.586-6.2-24.9-16.265-1.149-3.448-3.069-4.713-6.092-5.747A1175.336,1175.336,0,0,0,140.265,46.958C105,39.418,69.471,33.165,34.034,26.406c-4.012-.759-7.816-.966-11.908,1.69-6.9,4.517-16.368,1.471-20.311-5.552-3.8-6.77-1.4-16.345,5.092-20.3C14.264-2.2,23.091.2,28.057,7.337a16.092,16.092,0,0,0,7.506,5.9C91.885,32.153,149.357,46.717,207.622,58.2c21.357,4.207,42.886,7.517,64.311,11.345,4.138.747,6.736-.08,9.448-3.9a24.322,24.322,0,0,1,28.575-8.8c10.287,3.759,17.242,13.885,16.724,24.3a25.069,25.069,0,0,1-19.127,23.621,29.881,29.881,0,0,1-4.609.7Z" transform="translate(64.346 378.647)" fill="#1f3667"/>
                <path id="Path_109" data-name="Path 109" d="M344.709,119.242c-10.494-.057-17.632-5.161-21.494-13.885a10.908,10.908,0,0,0-5.621-5.471c-70.334-23.977-142.163-42.426-214.8-57.6C81.131,37.77,59.314,34.034,37.567,29.954c-3.954-.736-7.379-.632-11.127,2.012C18.5,37.6,6.831,34.379,2.153,26.011A17.633,17.633,0,0,1,9.486,1.93c8.977-4.494,19.678-.92,23.506,8.425,1.414,3.448,3.379,4.885,6.667,5.989,68.633,22.989,138.588,41.046,209.416,55.7,23.368,4.828,46.955,8.586,70.38,13.1,3.609.69,5.747.264,8.31-2.575a21.437,21.437,0,0,1,25.61-4.908c7.69,3.644,13.149,12.92,12.391,21.012-.966,10.184-7.759,18.092-17.483,20.242a21.57,21.57,0,0,1-3.575.322Z" transform="translate(37.882 430.376)" fill="#1f3667"/>
                <path id="Path_107" data-name="Path 107" d="M0,24.394C.356,10.084,7.333,2.187,19.1.256c9.724-1.621,20.563,4.6,23.253,14.414,1.207,4.379,3.563,5.747,7.173,6.9,38.552,11.494,76.748,24.322,115.725,34.058,48.483,12.1,97.61,21.644,146.473,32.184,5.023,1.08,10.173,1.655,15.253,2.529,4.391.759,7.989.368,11.966-2.759,7.563-5.943,18.7-3.126,24.012,5.08a17.368,17.368,0,0,1-4.6,23.414c-8.345,5.747-18.931,3.54-24.782-4.6a16.988,16.988,0,0,0-7.425-6.092c-57.667-20.322-116.622-36.115-176.266-49.23C115.036,48.487,79.829,42.36,44.771,35.6a8.276,8.276,0,0,0-8.1,2.425c-7.127,6.563-15.7,7.69-24.472,3.517C4.012,37.659.218,30.716,0,24.394Z" transform="translate(19.529 491.349)" fill="#1f3667"/>
                <path id="Path_110" data-name="Path 110" d="M50.363,27.858C70.295,33.422,90.1,39.939,110.341,44.41q68.392,15.1,137.22,28.426c16.46,3.218,33.334,4.506,49.989,6.448a11.943,11.943,0,0,0,6.3-1.6c7.977-4.127,16.529-1.943,20.851,5.563a14.839,14.839,0,0,1-5.069,20.069c-7.322,4.517-15.828,2.3-21.161-4.851a15.931,15.931,0,0,0-8.127-5.287c-41.84-10.954-83.472-22.9-125.714-32.069C126.985,52.928,88.674,47.778,50.6,41.571a12.253,12.253,0,0,0-8.563,2.494C33.053,51.307,21.225,52.514,11.6,46.5A25.678,25.678,0,0,1,.869,18.41C3.708,8.065,13.4.513,24.639.019,36.9-.5,48.7,9.7,49.984,21.973,50.157,23.7,50.2,25.41,50.363,27.858Z" transform="translate(32.063 556.714)" fill="#1f3667"/>
                <path id="Path_116" data-name="Path 116" d="M0,28.521C.391,13.1,11.874,1.165,27.656.061c14.207-1,27.678,10.345,29.242,25.1.368,3.448,1.977,4.529,4.6,5.425a653.824,653.824,0,0,0,97.358,25.38c21.23,3.759,42.667,6.345,64.047,9.2a14.057,14.057,0,0,0,6.989-1.4c6.621-2.885,13.736-.862,16.816,5.092a12.31,12.31,0,0,1-4.54,16.092c-5.69,3.448-12.173,1.724-16.644-3.9a14.116,14.116,0,0,0-6.253-4.23A623.251,623.251,0,0,0,125.1,52.74c-22.2-3.782-44.61-6.3-66.9-9.563-3.287-.483-4.977.483-7.035,3.046C44.184,54.97,34.862,58.786,23.747,56.74S4.885,47.936,1.437,36.924A62,62,0,0,1,0,28.521Z" transform="translate(69.84 617.649)" fill="#1f3667"/>
                <path id="Path_117" data-name="Path 117" d="M0,32.443C.069,15.649,13.184,1.3,29.575.1,47.484-1.224,62.909,11.029,65,28.73c.425,3.609,1.621,5.127,4.989,6.276,25.046,8.529,50.667,14.15,77.127,15.54a12.851,12.851,0,0,0,6.713-1.954c5.54-3.23,12.437-1.977,15.655,3.218a10.92,10.92,0,0,1-3.667,15.035c-4.931,3.011-11.575,1.149-15.161-3.667a15.208,15.208,0,0,0-6.575-5.4,312.036,312.036,0,0,0-74.357-15.15c-4.207-.356-6.218.954-8.127,4.874C54.771,61.558,39.254,68.19,24.012,64.328,9.862,60.684-.057,47.5,0,32.443Z" transform="translate(116.806 675.982)" fill="#1f3667"/>
                <path id="Path_105" data-name="Path 105" d="M112.246,35.175c.08,16.242-8.425,29.644-21.736,35.1-14.609,5.989-28.1,3.851-39.759-6.9-2.816-2.609-5.437-3.322-8.69-2.035-4.6,1.816-9.2,3.69-13.552,5.908-3.9,1.977-6.8,4.368-7.115,9.782-.379,6.333-7.276,10.23-13.023,8.632A11.011,11.011,0,0,1,.624,71.325a10.552,10.552,0,0,1,15.012-5.862,7.621,7.621,0,0,0,8.379,0c3.115-1.839,6.368-3.448,9.345-5.494C39.107,56.06,42.2,52.152,40.1,43.646c-3.448-13.9,1.391-26.529,13.046-35.552C65.108-1.17,78.43-2.446,91.9,4.083S111.89,21.865,112.246,35.175Z" transform="translate(139.205 735.951)" fill="#1f3667"/>
                <path id="Path_112" data-name="Path 112" d="M214.969,31.39a31.138,31.138,0,0,1-20.575,29.495,31.874,31.874,0,0,1-35.506-10.219,10.667,10.667,0,0,0-6.9-3.678C109.6,45.31,68.254,51.827,27.633,63.551A13.92,13.92,0,0,0,21.886,67.6C16.909,73,9.84,73.977,4.449,69.712A12.828,12.828,0,0,1,2.012,53.1c3.644-5.218,10.77-6.46,16.931-3.08A10.781,10.781,0,0,0,23.622,51.6,393.724,393.724,0,0,0,148.37,33.355c3.851-1.149,4.207-3.621,4.655-6.77a31.207,31.207,0,0,1,62.07,4.8Z" transform="translate(109.38 792.197)" fill="#1f3667"/>
                <path id="Path_114" data-name="Path 114" d="M275.45,52.468c-10.069,0-17.655-4.6-22.529-13.23a7.517,7.517,0,0,0-7.081-4.425A1129.921,1129.921,0,0,0,94.575,37.365c-20.357,1.632-40.633,4.391-60.92,6.9a15.459,15.459,0,0,0-7.747,3.9c-6.2,5.563-15.4,5.828-21.242.138a14.851,14.851,0,0,1-.494-21.127c5.931-6.2,15.621-6.609,21.449-.218,2.793,3.069,5.747,3.92,9.448,4.115,39.38,2.023,78.771,2.3,118.116-.632,26.851-2.012,53.656-4.736,80.461-7.161,1.9-.172,3.759-.816,5.655-.851,6.713-.1,10.9-2.2,14.046-9.287C258.427,1.709,273.174-2.751,284.9,1.7a25.978,25.978,0,0,1,16.529,28.736C298.979,43.974,288.853,52.514,275.45,52.468Z" transform="translate(68.29 859.637)" fill="#1f3667"/>
            </g>
        </svg>
    )
}

export default Dna