import React from 'react'
import styles from './home-indications.module.scss'
import { Link } from "gatsby";
import Dna from '../../components/dna';

const HomeIndications = () => {
    return (
        <div className={styles.wrapper}>
            <Dna className={styles.dna}/>
            <div className="container">
                <div>
                    <h2 className={styles.h2Small}><strong>Lead Indications</strong><br />[Parallel Development]</h2>
                    <h3>Ocular Injury From Sulphur Mustard Gas</h3>
                    <p>Sulphur mustard gas causes significant, DNA injury to multiple areas of the eye. An untreated injury has a high likelihood of resulting in permanent vision damage. INV-102 helps maintain cellular stability, leading to a more complete DNA repair and  significantly better  recovery.</p>
                </div>

                <div className={styles.divider}></div>

                <div className="relative z-10">
                    <h3>Acute Infectious Keratoconjunctivitis</h3>
                    <p>In the US alone, there are approximately 5 million cases of infectious conjunctivitis annually. 80% of those cases are viral, for which there is no approved treatment.  This leads to an over prescription of ineffective antibiotics and billions of dollars in lost productivity. INV-102 is expected to a be an effective treatment for both viral and bacterial conjunctivitis.</p>

                    <p><strong>BARDA funding will support the development of INV-102 for both indications, with the goal of having INV-102 readily available nationwide.</strong></p>
                    
                    <Link className="button" to="/science">Dive into the science</Link>
                </div>


                <div className={`${styles.team}`}>
                    <div className={styles.teamImage}>
                        <img src="/home-scientist.png" alt=""/>
                    </div>
                    <div className={styles.teamContent}>
                        <h2>A unique perspective on drug development</h2>
                        <p>Drawing on a wide breadth of knowledge, Invirsa’s leadership team and scientific advisory board bring a new approach to tackling big market pharmaceuticals.</p>
                        <Link className="button" to="/leadership">Meet the team</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeIndications