import React from 'react'
import styles from './home-investors.module.scss'

const HomeInvestors = () => {
    return (
        <div className={styles.wrapper}>
            <div className="container">
                <h2>Our Investors</h2>
                <div className={styles.logos}>
                    <img src="/cincytech@2x.png" alt="CincyTech"/>
                    <img src="/rev1ventures@2x.png" alt="Rev 1 Ventures"/>
                    <img src="/ohio-innovation-fund@2x.png" alt="Ohio Innovation Fund"/>
                </div>
            </div>
        </div>
    )
}

export default HomeInvestors