import React from 'react'
import NewsCard from '../../components/news-card'
import styles from './home-news.module.scss'

const HomeNews = () => {
    return (
        <div className={styles.newsWrapper}>
            <div className="container">
                <h4 className={styles.h4}>Latest News:</h4>
                <div className={styles.news}>
                    <div>
                        <NewsCard
                            date="Jun 14th, 2023"
                            url="/invirsa_series_b.pdf"
                            title="Invirsa Completes $7.7M Series B Financing"
                        />
                    </div>
                    <div>
                        <NewsCard
                            date="May 9th, 2023"
                            url="/phase_1_2a_dry_eye_enrollment.pdf"
                            title="Invirsa Completes Enrollment of Phase 1/2a Dry Eye Study"
                        />
                    </div>
                    <div>
                        <NewsCard
                            date="Aug 25th, 2022"
                            url="/invirsa_ind_20220825.pdf"
                            title="Invirsa Submits FDA INV-102 IND Application"
                        />
                    </div>
                    <div>
                        <NewsCard
                            date="Sept 29th, 2020"
                            url="https://www.medicalcountermeasures.gov/newsroom/2020/invirsa/"
                            title="BARDA partners with Invirsa to develop a treatment for ocular conditions associated with DNA damage, including sulfur mustard injury"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNews