import React from 'react'
import styles from './news-card.module.scss'

const NewsCard = ({url, date, title}) => {
    return (
        <a href={url} className={styles.card} target="_blank" rel="noreferrer">
            <span className={styles.cardDate}>{date}</span>
            <div>{title}</div>
        </a>
    )
}

export default NewsCard